import React from "react";
import PropTypes from 'prop-types';

export const HeaderText = ({ text }) => {
  return (
    // Uses the 'last-update' class to copy styling.
    <div className="last-update">
      <span>{text}</span>
    </div>
  );
};

HeaderText.propTypes = {
  text: PropTypes.string.isRequired,
};
