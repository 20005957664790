/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
//import { currencyFormat } from 'src/utils/NumberFormat';
import { PercentageComponent } from 'src/utils/NumberTDStyle';
import { CurrencyComponent } from 'src/utils/NumberTDStyle';
import * as navigationActions from 'src/actions/NavigationActions';

class PortFolioComponent extends Component {
   static propTypes = {
     title: PropTypes.string,
     period: PropTypes.string.isRequired,
     percentage: PropTypes.string,
     nameOfClass: PropTypes.string,
     headerInfo: PropTypes.object.isRequired,
     history: PropTypes.object.isRequired,
     portfolioId: PropTypes.number,
     portfolio: PropTypes.object.isRequired,
     performancePeriod: PropTypes.string.isRequired
   }

   onClickFund = (portfolioId) => {
     if (_.isNil(portfolioId)) {
       return;
     }
     const {
       history, headerInfo, portfolio: { code_publish }
     } = this.props;
     const url = '/publicInvestmentFunds/'.concat(portfolioId);
     if (history) {
       const location = {
         pathname: url,
         state: { headerInfo, title: code_publish, portfolioId }
       };
       navigationActions.navigateTo(history, location);
     }
   }

   render() {
     const {
       title, period, portfolio, performancePeriod
     } = this.props;

     let viewFundSiteClass = 'visibility-hidden';
     if (!_.isNil(portfolio.url)) {
       viewFundSiteClass = 'fund-website';
     } else {
       viewFundSiteClass = 'visibility-hidden';
     }
     return (
       // eslint-disable-next-line jsx-a11y/anchor-is-valid
       <div className="portfolio-wrapper">
         <a
           onClick={() => this.onClickFund(portfolio.id)}
           className="portfolio"
           title={title}
         >
           <div className="title">
             {portfolio.code_publish}
           </div>
           <div className="period">
             {period}
           </div>
           <div className="amount">
             <CurrencyComponent
               strong={false}
               type="div"
               amount={portfolio.totals.amount}
             />
           </div>
           <PercentageComponent
             amount={portfolio.totals[performancePeriod]}
             strong={false}
             type="div"
           />
           <span className="detail">Detail &gt;</span>
         </a>

         <a
           href={portfolio.url}
           target="_blank"
           className={viewFundSiteClass}
           rel="noopener noreferrer"
         >
          View Fund Site &gt;
         </a>

       </div>

     );
   }
}

export default PortFolioComponent;
