/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Header from "src/components/header/Header";
import * as actions from 'src/actions';
import * as navigationActions from 'src/actions/NavigationActions';


const propTypes = {
  user: PropTypes.object,
  errorMessage: PropTypes.string,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool
};

const FIELDS = {
  password_confirmation: {
    type: 'input',
    label: 'Password'
  },
  password: {
    type: 'input',
    label: 'Password Confirmation'
  }
};

const required = value => (value || typeof value === 'number' ? undefined : 'Required');
const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const maxLength15 = maxLength(15);

const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
const minLength5 = minLength(5);

class ResetPassword extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    user: PropTypes.object,
    resetPassword: PropTypes.func,
    history: PropTypes.object,
    submitting: PropTypes.bool,
    resetPasswordError: PropTypes.string,
    resetPasswordOk: PropTypes.bool
  }

  handleFormSubmit = ({ password, password_confirmation }) => {
    const { resetPassword, history } = this.props;

    resetPassword({ password, password_confirmation, history });
  }

  renderAlert() {
    const { resetPasswordError } = this.props;
    if (resetPasswordError) {
      return (
        <div className="error-message">
          Reset password error
        </div>
      );
    }
  }

  renderField = ({
    input, label, type, meta: { touched, error, warning }
  }) => {
    const class1 = `group ${error && touched && 'error'}`;
    return (
      <div className={class1}>
        <input {...input} type={type} className="form-control" />
        <span className="highlight" />
        <span className="bar" />
        <label className="material">
          {label}
        </label>
        {touched
          && ((error && <span className="error-message">{error}</span>)
            || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  goHome(e) {
    e.preventDefault();
    const { history } = this.props;

    if (history.location.pathname.includes('/resetPassword')) {
      const url = '/fundsList/';
      if (history) {
        const location = { pathname: url };
        const clearHistory = true;
        navigationActions.navigateTo(history, location, clearHistory);
      }
    }
  }


  render() {
    const classNavInfo = { bodyClassName: "page password" };
    const {
      user, history, resetPasswordError, resetPasswordOk
    } = this.props;
    const { handleSubmit, submitting, pristine } = this.props;
    let groupLoginClass = 'group';

    if (resetPasswordError) {
      groupLoginClass = 'group error';
    }

    let buttonClassName = 'blue';
    if (pristine || submitting) {
      buttonClassName = 'blue inactive';
    } else {
      buttonClassName = 'blue';
    }

    return (
      <Header
        authenticated
        classNavInfo={classNavInfo}
        activateSwiper={false}
        user={user}
        title="Password reset"
        history={history}
      >
        <div className="main-container">
          <div className="wrapper clearfix">
            <h1>Set a new password</h1>
            <p>You must set a new password in order to continue</p>
            {
              !resetPasswordOk && (
                <div className="password-form">
                  <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                    <div className={groupLoginClass}>
                      <Field
                        name="password"
                        component={this.renderField}
                        type="password"
                        label="Password"
                        validate={[required, maxLength15, minLength5]}
                      />
                    </div>

                    <div className={groupLoginClass}>
                      <Field
                        name="password_confirmation"
                        component={this.renderField}
                        type="password"
                        label="Password Confirmation"
                        validate={[required, maxLength15, minLength5]}
                      />
                      {this.renderAlert()}
                    </div>
                    <button
                      type="submit"
                      className={buttonClassName}
                      disabled={submitting || pristine}
                    >
                      Save
                    </button>
                  </form>
                </div>
              )
            }
            {
              resetPasswordOk && (
                <div className="password-form sent">
                  <img src="img/d-check-mail.svg" alt="Sent" />
                  <span className="success-message">
                    The password has been set
                  </span>
                  <a
                    className="button blue"
                    onClick={(e) => this.goHome(e)}
                  >
                    Continue
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </Header>
    );
  }
}


ResetPassword.propTypes = propTypes;

function validate(values) {
  const errors = {};
  _.each(FIELDS, (type, field) => {
    if (!values[field]) {
      errors[field] = `Enter a ${field}`;
    }
  });
  return errors;
}

function mapStateToProps(state) {
  const { data } = state.auth;
  const returnObj = {};
  if (data) {
    returnObj.user = data.user;
    returnObj.header = data.header;
  }
  return { ...returnObj, resetPasswordError: state.auth.error, resetPasswordOk: state.auth.resetPasswordOk };
}

const ComponentWithForm = reduxForm({
  form: 'ResetPasswordForm',
  validate
})(ResetPassword);

export default connect(mapStateToProps, actions)(ComponentWithForm);
