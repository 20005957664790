import React from "react";
import moment from "moment";

export const extractDate = (header) => {
  const dateTime = header.header["2"].value_date;
  const dateParsed = moment(dateTime).format("DD/MM/YYYY");

  return dateParsed;
};

export const HeaderTime = (header) => {
  const dateParsed = extractDate(header);
  const text = `Updated at ${dateParsed}`;

  return (
    <div className="last-update">
      <span>{text}</span>
    </div>
  );
};
