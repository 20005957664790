/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import _ from "lodash";
import { CurrencyComponent, PercentageComponent } from 'src/utils/NumberTDStyle';
import { currencyFormat } from 'src/utils/NumberFormat';

function getCostPrice(asset) {
  if (asset.asset_cost_price) {
    return (
      <tr>
        <td>Cost Price</td>
        <td>
          {`${currencyFormat(asset.asset_cost_price, false)} ${asset.asset_currency_code}`}
        </td>
      </tr>
    );
  }
  return (<React.Fragment />);
}

function getLastPrice(asset) {
  if (asset.asset_last_price) {
    return (
      <tr>
        <td>Last Price</td>
        <td>
          {`${currencyFormat(asset.asset_last_price, false)} ${asset.asset_currency_code}`}
        </td>
      </tr>
    );
  }
  return (<React.Fragment />);
}

function getTitles(asset) {
  if (asset.asset_titles) {
    return (
      <tr className="titles">
        <td>Titles</td>
        <td>{currencyFormat(asset.asset_titles, false, false)}</td>
      </tr>
    );
  }
  return (<React.Fragment />);
}

function getUnrealizedRateGains(asset) {
  if (asset.asset_unrealised_rate_gains) {
    return (
      <tr>
        <td>Unrealised Rate Gains</td>
        <CurrencyComponent
          strong={false}
          type="td"
          amount={asset.asset_unrealised_rate_gains}
        />
      </tr>
    );
  }
  return (<React.Fragment />);
}

function getUnrealizedPriceGains(asset) {
  if (asset.asset_unrealised_price_gains) {
    return (
      <tr>
        <td>Unrealised Price Gains</td>
        <CurrencyComponent
          strong={false}
          type="td"
          amount={asset.asset_unrealised_price_gains}
        />
      </tr>
    );
  }
  return (<React.Fragment />);
}

function getUnrealizedInterestGains(asset) {
  if (asset.asset_unrealised_interest_gains) {
    return (
      <tr>
        <td>Unrealised Interest Gains</td>
        <CurrencyComponent
          strong={false}
          type="td"
          amount={asset.asset_unrealised_interest_gains}
        />
      </tr>
    );
  }
  return (<React.Fragment />);
}

export const renderAsset = (
  asset,
  serviceId,
  toggle,
  isCollapsed,
  portfolioId,
  classNavInfo,
  linkToDrillDown
) => {
  const urlDrillDownDetail = `/fundDescription/${portfolioId}/drillDownDetail/${serviceId}/asset/${asset.asset_id}`;

  let showButton = (!_.isNil(asset.asset_cost_price)
    || !_.isNil(asset.asset_last_price)
    || !_.isNil(asset.asset_titles)
    || !_.isNil(asset.asset_unrealised_rate_gains));
  showButton = showButton && !isCollapsed(asset.asset_id);

  let displayStyle;

  if (asset && asset.asset_id && isCollapsed(asset.asset_id)) {
    displayStyle = {};
  } else {
    displayStyle = { display: "none" };
  }
  const text = asset.asset_amount + asset.asset_name;

  const costPrice = getCostPrice(asset);
  const lastPrice = getLastPrice(asset);
  const titles = getTitles(asset);
  const unrealizedRateGains = getUnrealizedRateGains(asset);
  const unrealizedPriceGains = getUnrealizedPriceGains(asset);
  const unrealizedInterestGains = getUnrealizedInterestGains(asset);

  return (
    <div className="asset" key={text}>
      <div className="content">
        <div
          className="label"
          style={{ color: asset.asset_type.color, borderColor: asset.asset_type.color }}
        >
          {asset.asset_type.name_publish}
        </div>
        <span className="name">{asset.asset_name}</span>
        {
          asset.asset_drill_down === 1 && (
            <a
              className="link-detail"
              onClick={(e) => {
                linkToDrillDown(e, {
                  pathname: urlDrillDownDetail
                });
              }}
            >
              Drill Down &gt;
            </a>
          )
        }
        <div
          className="nums-container"
          onClick={() => {
            if (showButton) {
              toggle(asset);
            }
          }}
        >
          <PercentageComponent
            strong={false}
            amount={asset.asset_weight}
            type="div"
          />
          <div className="amount">
            <CurrencyComponent
              strong={false}
              type="div"
              amount={asset.asset_amount}
            />
          </div>
        </div>
        <div
          className="more"
          onClick={() => {
            if (showButton) {
              toggle(asset);
            }
          }}
        >
          {showButton && (
            <img src="img/more.svg" alt="More" />
          )}
        </div>
      </div>
      <div
        onClick={() => {
          toggle(asset);
        }}
      >
        <table className="content-more" style={displayStyle}>
          <tbody>

            {costPrice}
            {lastPrice}
            {titles}
            {unrealizedRateGains}
            {unrealizedPriceGains}
            {unrealizedInterestGains}

            <tr>
              <td colSpan="2" className="less">
                <img src="img/less.svg" alt="Less" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {
        !_.isNil(asset.url)
        && asset.url.toLowerCase().startsWith('http')
        && (
          <div className="view-fund-link">
            <a href={asset.url} target="_blank" rel="noopener noreferrer">
              View Fund Site &gt;
            </a>
          </div>
        )
      }
    </div>
  );
};
