import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { currencyFormat } from "src/utils/NumberFormat";
import { HeaderTime } from "src/components/headerTime/headerTime";
import { renderAsset } from "./Asset";

class TabDetail extends Component {
  static propTypes = {
    portfolioList: PropTypes.array,
    headerInfo: PropTypes.object,
    advisor: PropTypes.object,
    history: PropTypes.object,
    data: PropTypes.object,
    autenthicated: PropTypes.bool,
    details: PropTypes.array.isRequired,
    portfolioId: PropTypes.string.isRequired,
    updateSwiperHeight: PropTypes.func,
    classNavInfo: PropTypes.object,
    linkToDrillDown: PropTypes.func
  };

  state = {
    //arrays to hold asset_id of expanded assets in tabs
    expandedArray: []
  };

  toggle = asset => {
    let { expandedArray } = this.state;

    const collapsedVal = expandedArray.find(val => {
      return val === asset.asset_id;
    });

    if (!_.isNil(collapsedVal)) {
      const newArray = _.remove(expandedArray, val => {
        return val !== asset.asset_id;
      });
      this.setState({ expandedArray: newArray });
    } else {
      expandedArray = [...expandedArray, asset.asset_id];
      this.setState({ expandedArray });
    }
    //this.forceUpdate();
  };

  isCollapsed = cardIndex => {
    const { expandedArray } = this.state;

    const collapsedVal = expandedArray.find(val => {
      return val === cardIndex;
    });

    if (!_.isNil(collapsedVal)) {
      return true;
    }
    return false;
  };

  renderAssets = (serviceId, items) => {
    const { portfolioId, classNavInfo, linkToDrillDown } = this.props;
    return items.map(asset => {
      return renderAsset(
        asset,
        serviceId,
        this.toggle,
        this.isCollapsed,
        portfolioId,
        classNavInfo,
        linkToDrillDown
      );
    });
  };

  renderServiceType = service => {
    return (
      <section key={service.name}>
        <div className="d-assets-frame" style={{ borderColor: service.color }}>
          <h2 className="title" style={{ color: service.color }}>
            {service.name}
          </h2>
          <span className="amount">{currencyFormat(service.totals)}</span>
          {this.renderAssets(service.id, service.items)}
        </div>
      </section>
    );
  };

  render() {
    const { headerInfo, details } = this.props;

    const result = details.map(service => {
      return this.renderServiceType(service);
    });
    return (
      <React.Fragment>
        {headerInfo && <HeaderTime header={headerInfo} />}
        {details.length > 0 && result}
      </React.Fragment>
    );
  }
}

export default TabDetail;
