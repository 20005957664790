/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as navigationActions from 'src/actions/NavigationActions';

export default class Header extends Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    goBack: PropTypes.func,
    fundData: PropTypes.object,
    user: PropTypes.object,
    classNavInfo: PropTypes.object,
    portfolioId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    title: PropTypes.string,
    activateSwiper: PropTypes.bool.isRequired,
    activateButtonBack: PropTypes.bool,
    state: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      lowerMenuModal: {
        visibility: 'hidden',
      },
      lowerMenuTransition: "modal-content2"
    };
  }

  signOut(e) {
    e.preventDefault();
    const { authenticated, history } = this.props;
    if (authenticated) {
      const location = { pathname: '/signout' };
      navigationActions.navigateTo(history, location);
    }
  }

  goHome(e) {
    e.preventDefault();
    const { history } = this.props;
    const clearHistory = true;

    if (history.location.pathname.includes('/publicInvestmentFunds')) {
      const url = '/publicInvestmentFunds/';
      const location = { pathname: url };
      navigationActions.navigateTo(history, location, clearHistory);
    } else {
      const url = '/fundsList/';
      if (history) {
        const location = { pathname: url };
        navigationActions.navigateTo(history, location, clearHistory);
      }
    }
  }

  goBack(e) {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.goBack(history);
  }

  renderBackButton = () => {
    const { history } = this.props;
    const canGoBack = history && history.goBack;
    if (canGoBack) {
      return (
        <button type="button" onClick={() => this.goBack()}>Atras</button>
      );
    }

    return (<div>&nbsp;</div>);
  }

  onClickAdvisor = (e) => {
    e.preventDefault();
    const {
      history, classNavInfo, portfolioId
    } = this.props;
    if (history) {
      const location = {
        pathname: `/advisor/${portfolioId}`,
        state: { classNavInfo }
      };
      navigationActions.navigateTo(history, location);
    }
  }

  onClickOpenLower = (e) => {
    e.preventDefault();
    this.setState(() => {
      return {
        lowerMenuModal: {
          visibility: 'visible'
        },
        lowerMenuTransition: "modal-content2 lower-menu-transition"
      };
    });
  }

  modalClose = (e) => {
    e.preventDefault();
    this.setState(() => {
      return {
        lowerMenuModal: {
          visibility: 'hidden'
        },
        lowerMenuTransition: "modal-content2"
      };
    });
  }

  historicalPerformance = (e) => {
    e.preventDefault();
    const { history, portfolioId, title } = this.props;

    const url = '/historical/'.concat(portfolioId);

    if (history) {
      const location = {
        pathname: url,
        state: { title }
      };
      navigationActions.navigateTo(history, location);
    } else {
      console.error('history is incorrect');
    }
  }

  investmentAndWidrawals = (e) => {
    e.preventDefault();
    const { history, portfolioId, title } = this.props;

    const url = '/investmentsAndWidrawals/'.concat(portfolioId);
    if (history) {
      if (history) {
        const location = {
          pathname: url,
          state: { title }
        };
        navigationActions.navigateTo(history, location);
      } else {
        console.error('history is incorrect.');
      }
    } else {
      console.error('fundData is incorrect.');
    }
  }

  signIn = e => {
    e.preventDefault();
    const { history } = this.props;
    if (history) {
      const location = { pathname: '/signin' };
      navigationActions.navigateTo(history, location);
    }
  }

  renderHeader = () => {
    const {
      activateSwiper, classNavInfo, activateButtonBack, history, user, portfolioId, title
    } = this.props;

    const isPublicInvestmentFunds = history.location.pathname.includes('/publicInvestmentFunds');

    let alternativeTitle = null;
    if ((classNavInfo.bodyClassName === "page historical"
      || classNavInfo.bodyClassName === "page investments")
      && _.isNil(title)
    ) {
      alternativeTitle = title;
    }
    const renderLogoutSignIn = () => {
      const { user, authenticated } = this.props;
      if (authenticated) {
        return (
          <div>
            <span>{user.email}</span>
            <br />
            <a
              title="Logout"
              onClick={(e) => this.signOut(e)}
            >
              Logout
            </a>
          </div>
        );
      } else {
        return (
          <a onClick={(e) => this.signIn(e)} title="Sign in">Sign in</a>
        );
      }
    };

    const renderPageTitle1 = () => {
      if (classNavInfo.bodyClassName === 'page home' || classNavInfo.bodyClassName === "page home fimarge") {
        return (
          <React.Fragment>
            <img className="logo white " src="img/logo-white.svg" alt="Fimarge" />
            <span className="page-title display-phone-only">{title}</span>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <img className="logo white display-desktop-only-inline" src="img/logo-white.svg" alt="Fimarge" />
          <span className="page-title display-phone-only">{title}</span>
        </React.Fragment>
      );
    };

    const renderNavLinks = () => {
      const bool = !classNavInfo.bodyClassName.includes('page advisor')
        && !classNavInfo.bodyClassName.includes('page detail fund fimarge')
        && !_.isNil(portfolioId);
      return bool;
    };

    const renderHomeLink = () => {
      const bool = classNavInfo.bodyClassName
        && classNavInfo.bodyClassName !== "page home"
        && classNavInfo.bodyClassName !== "page home fimarge"
        && classNavInfo.bodyClassName !== "page password";

      return bool;
    };


    let investmentsAndWidrawalsActive = "link investment";
    let historicalPerformanceActive = "link historical";
    if (classNavInfo.bodyClassName === "page investments") {
      investmentsAndWidrawalsActive = "link investment active";
      historicalPerformanceActive = "link historical";
    } else if (classNavInfo.bodyClassName === "page historical") {
      investmentsAndWidrawalsActive = "link investment";
      historicalPerformanceActive = "link historical active";
    }

    let phoneNumberHtml;
    if (!_.isNil(user && user.advisor)) {
      phoneNumberHtml = `tel:${user.advisor.phone_number}`;
    }
    return (
      <div className="header-container">
        <header className="clearfix">
          <div className="main-header">
            <div className="h-side left">
              {
                classNavInfo.bodyClassName !== "page home"
                && (
                  <div className="m-back-button">
                    <a title="Back" onClick={(e) => this.goBack(e)}>
                      <img src="img/m-back-button.svg" alt="Back" />
                    </a>
                  </div>
                )
              }
              <div className="d-user-info">
                {renderLogoutSignIn()}
              </div>
            </div>
            <div className="h-center">
              {
                renderPageTitle1()
              }
            </div>
            <div className="h-side right">
              {
                classNavInfo.bodyClassName === 'page advisor'
                && !_.isNil(phoneNumberHtml)
                && (
                  <div className="m-phone">
                    <a href={phoneNumberHtml}>
                      <img src="img/m-phone.svg" alt="Advisor" />
                    </a>
                  </div>
                )
              }
              <ul className="d-nav-links">
                <li>
                  <a href="https://goo.gl/maps/DTToUsjThht" title="Location" target="_blank" rel="noopener noreferrer">
                    <img src="img/d-location-white.svg" className="white" alt="Location" />
                    <img src="img/d-location-blue.svg" className="blue" alt="Location" />
                    Location
                  </a>
                </li>
                <li>
                  <a href="https://fimarge.com/" title="Fimarge Website" target="_blank" rel="noopener noreferrer">
                    <img src="img/d-web-white.svg" className="white" alt="Fimarge Website" />
                    <img src="img/d-web-blue.svg" className="blue" alt="Fimarge Website" />
                    Web
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-navigation-header">
            <div className="home-link">
              {
                renderHomeLink()
                && (
                  <a title="Home" onClick={(e) => this.goHome(e)}>
                    <img
                      src="img/d-chevron-bck.svg"
                      alt="Home"
                    />
                    &nbsp;
                    {!isPublicInvestmentFunds && (
                      <React.Fragment>Home</React.Fragment>
                    )}
                    {isPublicInvestmentFunds && (
                      <React.Fragment>Fimarge Investment Funds</React.Fragment>
                    )}

                  </a>

                )
              }
            </div>
            <div className="nav-links">
              {
                renderNavLinks() && (
                  <div className="link-section left">
                    <div className={investmentsAndWidrawalsActive}>
                      <a
                        title="Inflows & Outflows"
                        onClick={(e) => this.investmentAndWidrawals(e)}
                      >
                        Inflows & Outflows
                      </a>
                    </div>
                  </div>
                )
              }
              <div className="link-section">
                {
                  !_.isNil(title) && (
                    <div className="title">
                      {title}
                    </div>
                  )
                }
                {
                  !_.isNil(alternativeTitle) && (
                    <div className="title">
                      {alternativeTitle}
                    </div>
                  )
                }
              </div>
              {
                renderNavLinks() && (
                  <div className="link-section right">
                    <div className={historicalPerformanceActive}>
                      <a
                        title="Historical Performance"
                        onClick={(e) => this.historicalPerformance(e)}
                      >
                        Historical Performance
                      </a>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          {
            activateSwiper && (
              <div className="tab-header">
                <div className="swiper-pagination" />
              </div>
            )
          }
          {
            (activateButtonBack || false) && (
              <div className="d-close-page">
                <a title="Close" onClick={(e) => this.goBack(e)}>
                  <img src="img/d-close.svg" alt="Close" />
                </a>
              </div>
            )
          }
        </header>
      </div>
    );
  }

  renderFooter = () => {
    let homeLinkClass = 'f-section';
    let advisorLinkClass = 'f-section';

    const { classNavInfo } = this.props;

    if (classNavInfo.bodyClassName === 'page advisor') {
      homeLinkClass = 'f-section';
      advisorLinkClass = 'f-section active';
    } else if (classNavInfo.bodyClassName === 'page home') {
      homeLinkClass = 'f-section active';
      advisorLinkClass = 'f-section';
    }

    return (
      <div className="footer-container">
        <footer>
          <div className={homeLinkClass}>
            <a
              title="Home"
              onClick={(e) => this.goHome(e)}
            >
              <img src="img/f-home-off.svg" alt="Home" />
            </a>

          </div>
          <div className="f-section" id="openLowerMenu">
            <a
              title="More"
              onClick={(e) => this.onClickOpenLower(e)}
            >
              <img src="img/menu.svg" alt="More" />
            </a>
          </div>
          <div className={advisorLinkClass}>
            <a
              title="Advisor"
              onClick={(e) => this.onClickAdvisor(e)}
            >
              <img src="img/f-advisor-off.svg" alt="Advisor" />
            </a>
          </div>
        </footer>
      </div>
    );
  }

  renderLowerModalMenu = () => {
    const { lowerMenuModal, lowerMenuTransition } = this.state;
    const { user, authenticated, portfolioId } = this.props;


    return (
      <div id="lowerMenu" className="lower-menu-modal" style={lowerMenuModal}>
        <div className={lowerMenuTransition}>
          <div className="user-info">
            <span className="username">{user.email}</span>
            <br />
            <a
              className="logout"
              title="Log out"
              onClick={(e) => this.signOut(e)}
            >
              Log out
            </a>
          </div>
          {
            authenticated
            && !_.isNil(portfolioId)
            && (
              <ul className="page-links">
                <li>
                  <a
                    title="Inflows & Outflows"
                    onClick={(e) => this.investmentAndWidrawals(e)}
                  >
                    <img src="img/investment-off.svg" alt="Inflows & Outflows" />
                    Inflows & Outflows
                  </a>
                </li>
                <li>
                  <a
                    title="Historical Performance"
                    onClick={(e) => this.historicalPerformance(e)}
                  >
                    <img src="img/historical-off.svg" alt="Historical Performance" />
                    Historical Performance
                  </a>
                </li>
              </ul>

            )
          }
          <div className="external-links">
            <div className="link">
              <a href="https://goo.gl/maps/DTToUsjThht" title="Location" target="_blank" rel="noopener noreferrer">
                <img src="img/location-blue.svg" alt="Location" />
                <span>Location</span>
              </a>
            </div>
            <div className="link">
              <a href="https://fimarge.com/" title="Fimarge Website" target="_blank" rel="noopener noreferrer">
                <img src="img/web-blue.svg" alt="Fimarge Website" />
                <span>Web</span>
              </a>
            </div>
            <div className="link privacy">
              <a href="https://fimarge.com/en/privacy-policy/" title="Fimarge Privacy Policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="modal-close" onClick={(e) => this.modalClose(e)}>
            <img src="img/m-close.svg" alt="Close" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { authenticated, children, classNavInfo } = this.props;
    // className="page home" is needed by swiper
    return (
      <div className={classNavInfo.bodyClassName}>
        {this.renderHeader()}
        {children}
        {
          authenticated
          && this.renderFooter()
        }
        {
          authenticated
          && this.renderLowerModalMenu()
        }
      </div>
    );
  }
}
