/* eslint-disable no-else-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import * as advisorActions from 'src/actions/AdvisorActions';
import Header from 'src/components/header/Header';

import { styles } from './styles';

library.add(faCheckCircle);

class Advisor extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func,
    postMessageToAdvisor: PropTypes.func,
    messagePostSuccess: PropTypes.object,
    submitSucceeded: PropTypes.bool, // redux-form prop
    reset: PropTypes.func, // redux-form prop func para lipiar el form
    user: PropTypes.object,
    match: PropTypes.object,
  }

  state = {
    cleanMessage: false,
    formClass: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const returnObject = {};
    const { messagePostSuccess } = nextProps;

    if (messagePostSuccess && messagePostSuccess.success && !prevState.cleanMessage) {
      returnObject.messagePostSuccess = messagePostSuccess.success;
      returnObject.cleanMessage = false;

      return returnObject;
    } else if (messagePostSuccess && messagePostSuccess.success === false) {
      returnObject.messagePostSuccess = false;
      returnObject.cleanMessage = false;

      return returnObject;
    }
    returnObject.messagePostSuccess = undefined;
    returnObject.cleanMessage = false;

    return returnObject;
  }

  handleFormSubmit = ({ message }) => {
    const { postMessageToAdvisor } = this.props;
    postMessageToAdvisor(message);
  }

  required = (value) => {
    if (value || typeof value === 'number') {
      this.setState((prevState) => {
        if (prevState.formClass === 'error') {
          return { formClass: '' };
        }
      });
      return undefined;
    } else {
      this.setState((prevState) => {
        if (prevState.formClass === '') {
          return { formClass: 'error' };
        }
      });

      return 'Error: message is empty.';
    }
  }

  onClickNewMessage = () => {
    const { reset } = this.props;
    reset();
    this.setState({ cleanMessage: true });
  }

  renderField = ({
    input, meta: { touched, error, warning },
  }) => {
    const class1 = `group ${error && touched && 'error'}`;
    return (
      <div className={class1}>
        {touched
          && ((error && <span className="error-message">{error}</span>)
            || (warning && <span className="error-message">Error: message is empty.</span>
            ))}
        <textarea {...input} name="message" placeholder="Write here your message" />
      </div>
    );
  }

  render() {
    const { user, history, match: { params: { portfolioId } } } = this.props;
    const { handleSubmit, submitSucceeded } = this.props;
    const { messagePostSuccess, formClass } = this.state;

    const classNavInfo = { bodyClassName: 'page advisor' };

    return (
      <Header
        authenticated
        history={history}
        user={user}
        portfolioId={portfolioId}
        activateSwiper={false}
        classNavInfo={classNavInfo}
        title="Advisor"
        advisor={user.advisor}
        activateButtonBack
      >
        <div className="main-container">
          <div className="wrapper clearfix">
            <div className="advisor-contact">
              <img
                src={user.advisor.image_url}
                alt="Advisor"
              />
              <h1>{user.advisor.full_name}</h1>
              <span>{user.advisor.mail}</span>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span>{user.advisor.phone_number}</span>
            </div>
            {
              !submitSucceeded && (
                <div className="advisor-form">
                  <form
                    className={formClass}
                    onSubmit={handleSubmit(this.handleFormSubmit)}
                  >
                    <Field
                      name="message"
                      component={this.renderField}
                      validate={[this.required]}
                      style={styles.textAreaFileldStyle}
                    // component="textarea"
                    />
                    <button type="submit" className="blue">Send</button>
                  </form>
                </div>
              )
            }
            {
              messagePostSuccess && submitSucceeded && (
                <div className="advisor-form sent">
                  <img src="img/d-check-mail.svg" alt="Sent" />
                  <span className="success-message">
                    Your message has been sent.
                    <br />
                    Thank you
                  </span>
                </div>

              )
            }
            {messagePostSuccess === false && (
              <div style={styles.messageResultSyle}>
                <div style={styles.messageContainerStyle}>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="red"
                    size="5x"
                  />
                </div>
                <div style={styles.messageOkStyle}>
                  Error sending message.
                </div>
                <button
                  type="button"
                  onClick={this.onClickNewMessage}
                >
                  New Message
                </button>
              </div>
            )
            }
          </div>
        </div>
      </Header>

    );
  }
}
const mapStateToProps = state => ({
  user: state.auth.data.user,
  messagePostSuccess: state.advisor.success,
});

const ComponentWithForm = reduxForm({
  form: 'advisorForm',
})(Advisor);


export default connect(mapStateToProps, advisorActions)(ComponentWithForm);
