import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as navigationActions from 'src/actions/NavigationActions';
import * as actions from 'src/actions';

const FIELDS = {
  userName: {
    type: 'input',
    label: 'userName'
  },
  password: {
    type: 'input',
    label: 'password'
  }
};


const required = value => (value || typeof value === 'number' ? undefined : 'Required');
const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const maxLength15 = maxLength(15);

const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
const minLength5 = minLength(5);

class SignIn extends PureComponent {
  static propTypes = {
    loadRemeberMe: PropTypes.func,
    handleSubmit: PropTypes.func,
    loginErrorMessage: PropTypes.string,
    logInUser: PropTypes.func,
    history: PropTypes.object,
    rememberUserNameValue: PropTypes.bool,
    setRememberMe: PropTypes.func,
    submitting: PropTypes.bool,
    initialValues: PropTypes.object,
    pristine: PropTypes.bool,
    dirty: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    const { loadRemeberMe } = props;
    // localStorage.clear();
    sessionStorage.removeItem('advisor');
    sessionStorage.removeItem('data');
    sessionStorage.removeItem('token');
    loadRemeberMe();
  }

  state = {
    buttonClassName: 'white inactive'
  };

  static getDerivedStateFromProps(nextProps) {
    let buttonClassName = 'white';
    if (nextProps.dirty) {
      buttonClassName = 'white';
    } else if (nextProps.pristine || nextProps.submitting) {
      buttonClassName = 'white inactive';
    } else {
      buttonClassName = 'white';
    }

    return { buttonClassName };
  }

  handleFormSubmit = ({ userName, password }) => {
    const { logInUser, history } = this.props;
    const { rememberUserNameValue } = this.props;

    logInUser({
      userName, password, history, rememberUserNameValue
    });
  }

  renderField = ({
    input, label, type, meta: { touched, error, warning }
  }) => {
    const class1 = `group ${error && touched && 'error'}`;
    return (
      <div className={class1}>
        <input {...input} type={type} className="form-control" />
        <span className="highlight" />
        <span className="bar" />
        <label className="material">
          {label}
        </label>
        {touched
          && ((error && <span className="error-message">{error}</span>)
            || (warning && <span>{warning}</span>))}
      </div>
    );
  }

  renderBadLogin() {
    const { loginErrorMessage } = this.props;
    if (loginErrorMessage) {
      return (
        <div className="error-message">Incorrect Password</div>
      );
    }
  }

  onSwitchChange = (e) => {
    e.preventDefault();
    const { setRememberMe, rememberUserNameValue } = this.props;

    setRememberMe(rememberUserNameValue);
  }

  goToInvestmentFunds(e) {
    e.preventDefault();
    const { history } = this.props;
    const location = { pathname: '/publicInvestmentFunds' };
    navigationActions.navigateTo(history, location);
    //history.push(url);
  }

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      handleSubmit, rememberUserNameValue, loginErrorMessage, submitting, pristine, dirty
    } = this.props;
    let switchInitialVal = Boolean(rememberUserNameValue);

    let groupLoginClass = 'group';

    if (loginErrorMessage) {
      groupLoginClass = 'group error';
    }

    // first load
    if (_.isNil(switchInitialVal)) {
      switchInitialVal = true;
    }
    const { buttonClassName } = this.state;
    return (
      <div className="page signin">
        <div className="header-container">
          <header className="clearfix">
            <div className="main-header">
              <div className="h-center">
                <img className="logo white" src="img/logo-white.svg" alt="Fimarge" />
                <img className="logo blue" src="img/logo.svg" alt="Fimarge" />
              </div>
              <div className="h-side right">
                <div className="m-phone">
                  <img src="img/m-phone.svg" alt="Advisor" />
                </div>
                <ul className="d-nav-links">
                  <li>
                    <a href="https://goo.gl/maps/DTToUsjThht" title="Location" target="_blank" rel="noopener noreferrer">
                      <img src="img/d-location-white.svg" className="white" alt="Location" />
                      <img src="img/d-location-blue.svg" className="blue" alt="Location" />
                      Location
                    </a>
                  </li>
                  <li>
                    <a href="https://fimarge.com/" title="Fimarge Website" target="_blank" rel="noopener noreferrer">
                      <img src="img/d-web-white.svg" className="white" alt="Fimarge Website" />
                      <img src="img/d-web-blue.svg" className="blue" alt="Fimarge Website" />
                      Web
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </header>
        </div>

        <div className="main-container">
          <div className="wrapper clearfix">
            <div className="content-container">
              <div className="logo">
                <img src="img/logo-white.svg" alt="Fimarge" />
              </div>
              <div className="signin-form">
                <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                  <div className="group">
                    <Field
                      name="userName"
                      component={this.renderField}
                      type="text"
                      label="User Name"
                      validate={[required, maxLength15, minLength5]}
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <span className="error-message" />
                  </div>
                  <div className={groupLoginClass}>
                    <Field
                      name="password"
                      component={this.renderField}
                      type="password"
                      label="Password"
                      validate={[required, maxLength15, minLength5]}
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    {this.renderBadLogin()}
                  </div>
                  <div className="group">
                    <span className="remember">Remember username</span>
                    <label className="switch">
                      {
                        switchInitialVal && (
                          <input
                            id="switchRef"
                            type="checkbox"
                            onChange={(e) => this.onSwitchChange(e)}
                            checked
                          />
                        )
                      }
                      {
                        switchInitialVal === false && (
                          <input
                            id="switchRef"
                            type="checkbox"
                            onClick={(e) => this.onSwitchChange(e)}
                          />
                        )
                      }
                      <span className="slider round" />
                    </label>
                  </div>
                  <div className="links">
                    <button
                      className={buttonClassName}
                      disabled={pristine || submitting}
                      type="submit"
                    >
                      Sign in
                    </button>
                    <a
                      href="a"
                      className="investment-funds"
                      onClick={(e) => this.goToInvestmentFunds(e)}
                    >
                      Fimarge Investment Funds&nbsp;&nbsp;&gt;
                    </a>
                    <div className="external">
                      <div className="link">
                        <a
                          href="https://goo.gl/maps/DTToUsjThht"
                          title="Location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="img/d-location-white.svg" alt="Location" />
                          <span>Location</span>
                        </a>
                      </div>
                      <div className="link">
                        <a href="https://fimarge.com/" title="Fimarge Website" target="_blank" rel="noopener noreferrer">
                          <img src="img/d-web-white.svg" alt="Fimarge Website" />
                          <span>Web</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  _.each(FIELDS, (type, field) => {
    if (!values[field]) {
      errors[field] = `Enter a ${field}`;
    }
  });
  return errors;
}

function mapStateToProps(state) {
  return {
    loginErrorMessage: state.auth.error,
    initialValues: state.auth.data,
    rememberUserNameValue: state.auth.rememberUserNameValue
  };
}
const ComponentWithForm = reduxForm({
  form: 'SignInForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate
})(SignIn);

export default connect(mapStateToProps, actions)(ComponentWithForm);
