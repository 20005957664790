import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { HeaderTime, extractDate } from 'src/components/headerTime/headerTime';
import { CurrencyComponent, PercentageComponent } from 'src/utils/NumberTDStyle';
import { formatDate } from 'src/utils/DateFormat';

import Bar from "./bar";
import Doughnut from "./ConfiguredDoughnut";

class TabSummary extends PureComponent {
  static propTypes = {
    portfolioList: PropTypes.array,
    headerInfo: PropTypes.object,
    advisor: PropTypes.object,
    history: PropTypes.object,
    data: PropTypes.object,
    autenthicated: PropTypes.bool
  };

  render() {
    const {
      data: { summary_graphics, summary_text },
      headerInfo
    } = this.props;
    const geographicalAllocation = summary_graphics[1];
    const allocationByAssetClass = summary_graphics[2];
    const allocationByCurrency = summary_graphics[3];
    const monthlyPerformanceData = summary_graphics[4];

    let initialDateString = null;
    let wighted_average_capital_text = null;
    let currentPorfolio = '';
    if (summary_text) {
      initialDateString = `Portfolio at ${formatDate(summary_text.initial_date)}`;
      wighted_average_capital_text = `Daily weighted average capital (${
        summary_text.number_days
      } days )`;
      const date = extractDate({ header: headerInfo });
      currentPorfolio = `Current portfolio (${date})`;
    }

    return (
      <React.Fragment>
        {headerInfo && <HeaderTime header={headerInfo} />}
        {summary_text && (
          <div className="summary-text">
            <table>
              <tbody>
                <tr>
                  <td>{initialDateString}</td>
                  <CurrencyComponent
                    amount={summary_text.initial_amount}
                    strong={false}
                    type="td"
                  />
                </tr>
                <tr>
                  <td>Net inflows & outflows</td>
                  <CurrencyComponent
                    strong={false}
                    type="td"
                    amount={summary_text.net_inflows_outflows}
                  />
                </tr>
                <tr>
                  <td>Net profit</td>
                  <CurrencyComponent
                    amount={summary_text.net_profit}
                    strong={false}
                    type="td"
                  />
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>{currentPorfolio}</strong>
                  </td>
                  <CurrencyComponent
                    amount={summary_text.amount}
                    strong
                    type="td"
                  />
                </tr>
                <tr>
                  <td>{wighted_average_capital_text}</td>
                  <CurrencyComponent
                    amount={summary_text.wighted_average_capital}
                    strong={false}
                    type="td"
                  />
                </tr>
                <tr>
                  <td>
                    <strong>Year-to-date net return</strong>
                  </td>
                  <PercentageComponent
                    amount={summary_text.net_return}
                    strong
                    type="td"
                  />
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="summary-graphics">
          {monthlyPerformanceData && (
            <section className="full-width">
              <span className="graph-title">
                Monthly performance of the last 12 months (%)
              </span>
              <Bar
                data={monthlyPerformanceData}
                width={960}
                height={473}
              />
            </section>
          )}
          {allocationByAssetClass && (
            <section className="margin-right">
              <span className="graph-title">Allocation by Asset Class</span>
              <div className="graph-wrapper">
                <Doughnut
                  name="Allocation by Asset Class"
                  data={allocationByAssetClass}
                  fontColor="red"
                  width={720}
                  height={360}
                />
              </div>
            </section>
          )}
          {allocationByCurrency && (
            <section className="margin-left">
              <span className="graph-title">Allocation by Currency</span>
              <div className="graph-wrapper">
                <Doughnut
                  name="Allocation by Currency"
                  data={allocationByCurrency}
                  width={720}
                  height={360}
                />
              </div>
            </section>
          )}
          {geographicalAllocation && (
            <section className="margin-right">
              <span className="graph-title">Geographical Allocation</span>
              <div className="graph-wrapper">
                <Doughnut
                  name="Geographical Allocation"
                  data={geographicalAllocation}
                  width={720}
                  height={360}
                />
              </div>
            </section>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default TabSummary;
