import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as consts from 'src/utils/consts';
import TabSummary from "src/components/fundDescription/tabSummary/TabSummary";
import { percentageFormat } from 'src/utils/NumberFormat';
import { HeaderTime } from '../../headerTime/headerTime';
import { HeaderText } from '../../headerText/headerText';

const propTypes = {
  portfolioList: PropTypes.array,
  headerInfo: PropTypes.object,
  advisor: PropTypes.object,
  history: PropTypes.object,
  data: PropTypes.object,
  autenthicated: PropTypes.bool,
  portfolioId: PropTypes.number.isRequired,
};

class SwiperPublicFundsDescription extends Component {
  shouldComponentUpdate() {
    return false;
  }

  state = {
    portfolio: null,
  }

  static getDerivedStateFromProps(nextProps) {
    const { publicFunds, portfolioId } = nextProps;

    const fund = publicFunds.find((portfolio) => {
      return (portfolio.id.toString() === portfolioId.toString());
    });

    return { portfolio: fund };
  }

  componentDidMount() {
    this.swiper = new global.Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet(index, className) {
          const tabsNames = [consts.SUMMARY, consts.DETAIL];
          const src = `<span class="${className}">${tabsNames[index]}</span>`;
          return src;
        },
      },
      initialSlide: 1,
      autoHeight: true,
      on: {
        init() {
          this.updateAutoHeight();
        },
        click() {
          this.updateAutoHeight();
        },
      },
    });
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  renderDrillDown = (item) => {
    const key = `${item.asset_name}${item.asset_weight}`;
    return (
      <tr key={key}>
        <td>
          <div className="label" style={{ color: item.asset_type.color, borderColor: item.asset_type.color }}>
            {item.asset_type.name_publish}
          </div>
        </td>
        <td>{item.asset_name}</td>
        <td>{percentageFormat(item.asset_weight)}</td>
      </tr>

    );
  }

  renderAsset() {
    const { portfolio } = this.state;

    if (_.isNil(portfolio) || _.isNil(portfolio.details[0]) || _.isNil(portfolio.details[0].drilldown)) {
      return;
    }

    const drillDownArray = portfolio.details[0].drilldown;
    const result = drillDownArray.map((item) => {
      return this.renderDrillDown(item);
    });
    return result;
  }

  renderPeriod = option => {
    const { portfolio } = this.state;
    const { headerInfo } = this.props;

    if (consts.SUMMARY === option) {
      return (
        <React.Fragment>
          <HeaderTime header={headerInfo} />
          <TabSummary data={
            {
              summary_graphics: portfolio.summary_graphics,
            }
          }
          />
        </React.Fragment>
      );
    } if (consts.DETAIL === option) {
      const { portfolio } = this.state;

      return (
        <React.Fragment>
          <HeaderText text="Top Holdings" />
          <h1>{portfolio.code_publish}</h1>
          <div className="assets">
            <table>
              <thead>
                <tr>
                  <th>
                    Asset
                  </th>
                  <th>
                    <span className="display-desktop-only-inline">Subject</span>
                  </th>
                  <th>
                    Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderAsset()}
              </tbody>
            </table>
          </div>
        </React.Fragment>

      );
    }

    return (
      <React.Fragment>
        <HeaderTime header={headerInfo} />
        <div>{option}</div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="main-container">
          <div className="wrapper clearfix">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {this.renderPeriod(consts.SUMMARY)}
                </div>
                <div className="swiper-slide">
                  {this.renderPeriod(consts.DETAIL)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


SwiperPublicFundsDescription.propTypes = propTypes;


export default SwiperPublicFundsDescription;
