
let navigationStack = [];
export function navigateTo(history, location, clearHistory) {
  if (clearHistory) {
    navigationStack = [];
  }
  navigationStack.push(location);
  history.push(location);
}

export function goBack(history) {
  if (navigationStack.length > 1) {
    navigationStack.pop();
    history.push(navigationStack[navigationStack.length - 1]);
  } else if (navigationStack.length === 0 && history.location.pathname === "/publicInvestmentFunds") {
    history.replace('/signIn');
  } else if (navigationStack.length === 1) {
    navigationStack.pop();
    history.replace('/signIn');
  } else if (navigationStack.length === 0) {
    history.replace('/fundsList');
  }
}
