import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'src/components/header/Header';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as publicInvestmentActions from 'src/actions/PublicInvestmentFundsAction';
import SwiperPublicFundsDescription from './SwiperPublicFundsDescription';

const propTypes = {
  publicFunds: PropTypes.array,
  history: PropTypes.object,
  header: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
};


class PublicFundsDescription extends Component {
  render() {
    const { publicFunds, history, header } = this.props;
    const { match: { params: { portfolioId } } } = this.props;
    const fund = publicFunds.find((portfolio) => {
      return (portfolio.id.toString() === portfolioId.toString());
    });
    const title = fund.code_publish;

    if (_.isNil(publicFunds)) {
      return (
        <div>
          Loading
        </div>
      );
    }
    const classNavInfo = { bodyClassName: "page detail fund fimarge" };

    return (
      <Header
        authenticated={false}
        history={history}
        header={header}
        portfolioId={portfolioId}
        activateSwiper
        classNavInfo={classNavInfo}
        title={title}
      >
        <SwiperPublicFundsDescription
          portfolioId={portfolioId}
          publicFunds={publicFunds}
          headerInfo={header}
          classNavInfo={classNavInfo}
        />
      </Header>
    );
  }
}


PublicFundsDescription.propTypes = propTypes;


const mapStateToProps = (state) => {
  const { publicFunds, header } = state.fundData;
  return { publicFunds, header };
};
export default connect(mapStateToProps, publicInvestmentActions)(PublicFundsDescription);
