/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable no-return-assign */
import React, { Component } from "react";
import PropTypes from "prop-types";
import * as consts from "src/utils/consts";
import * as navigationActions from 'src/actions/NavigationActions';
import TabSummary from "./tabSummary/TabSummary";
import TabDetail from "./tabDetail/TabDetail";
import TabPerformance from "./tabPerformance/TabPerformance";


class SwiperFundDescriptionComponent extends Component {
  static propTypes = {
    portfolioList: PropTypes.array,
    headerInfo: PropTypes.object,
    history: PropTypes.object,
    data: PropTypes.object,
    autenthicated: PropTypes.bool,
    portfolioId: PropTypes.string.isRequired,
    user: PropTypes.object,
    classNavInfo: PropTypes.object,
    linkToDrillDown: PropTypes.func
  };

  /*
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
*/

  componentDidMount() {
    this.swiper = new global.Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
          const tabsNames = [consts.SUMMARY, consts.DETAIL, consts.PERFORMANCE];
          const src = `<span class="${className}">${tabsNames[index]}</span>`;
          return src;
        }
      },
      initialSlide: 1,
      autoHeight: true,
      on: {
        init() {
          this.updateAutoHeight();
        },
        click() {
          this.updateAutoHeight();
        }
      }
    });
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  renderPeriod = option => {
    const { portfolioId } = this.props;
    const {
      data, headerInfo, classNavInfo, linkToDrillDown
    } = this.props;

    if (consts.SUMMARY === option) {
      return <TabSummary data={data} headerInfo={headerInfo} />;
    } else if (consts.DETAIL === option) {
      return (
        <TabDetail
          data={data}
          headerInfo={headerInfo}
          portfolioId={portfolioId}
          details={data.details}
          classNavInfo={classNavInfo}
          linkToDrillDown={linkToDrillDown}
        />
      );
    } else if (consts.PERFORMANCE === option) {
      return (
        <TabPerformance
          headerInfo={headerInfo}
          portfolioId={portfolioId}
          servicesPerformance={data.performances}
          classNavInfo={classNavInfo}
        />
      );
    }

    return <div>{option}</div>;
  };

  linkToAdvisor = (e, location) => {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.navigateTo(history, location);
  }

  render() {
    const {
      user, portfolioId, classNavInfo
    } = this.props;

    return (
      <React.Fragment>

        <div className="main-container">
          <div className="wrapper clearfix">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {this.renderPeriod(consts.SUMMARY)}
                </div>
                <div className="swiper-slide">
                  {this.renderPeriod(consts.DETAIL)}
                </div>
                <div className="swiper-slide">
                  {this.renderPeriod(consts.PERFORMANCE)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="advisor-link"
        >
          <a
            onClick={(e) => {
              this.linkToAdvisor(e, {
                pathname: `/advisor/${portfolioId}`,
                state: { classNavInfo }
              });
            }}
          >
            <img src={user.advisor.image_url} alt="Advisor" />
            <span>Your Advisor</span>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default SwiperFundDescriptionComponent;
