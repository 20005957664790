import {
  AUTH_USER, UNAUTH_USER, AUTH_ERROR, FETCH_MESSAGE, LOAD_USER_NAME, RESET_PASSWORD_ERROR, RESET_PASSWORD_OK
} from '../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state, error: '', authenticated: true, data: action.data, resetPasswordOk: false
      };
    case UNAUTH_USER:
      return { ...state, authenticated: false };
    case AUTH_ERROR:
      return { ...state, error: action.payload };
    case FETCH_MESSAGE:
      return { ...state, message: action.payload, error: '' };
    case LOAD_USER_NAME:
      return {
        ...state, data: action.payload.data, rememberUserNameValue: action.payload.rememberUserNameValue, error: ''
      };
    case RESET_PASSWORD_ERROR:
      return { ...state, error: action };
    case RESET_PASSWORD_OK: {
      const resetPasswordOk = true;
      return { ...state, resetPasswordOk };
    }
    default:
      return state;
  }
}
