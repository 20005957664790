import React from 'react';
import {
  Router, Route, Switch, Redirect
} from 'react-router-dom';
import createHashHistory from 'history/createHashHistory';
import SignIn from 'src/components/auth/signin';
import SignOut from 'src/components/auth/signout';
import SignUp from 'src/components/auth/signup';
import ResetPassword from 'src/components/auth/resetPassword';

import FundsList from 'src/components/fundsList/FundsList';
import FundDescription from 'src/components/fundDescription/FundDescription';
import DrillDownDetail from 'src/components/fundDescription/tabDetail/drillDownPage/DrillDownDetail';
import InvestmentsAndWithdrawals from 'src/components/investmentsAndWidrawals';
import PublicInvestmentFunds from 'src/components/fimargeFunds/PublicInvestmentFunds';
import PublicFundsDescription from 'src/components/fimargeFunds/publicFundsDescription/PublicFundsDescription';
import Advisor from 'src/components/advisor';
import Historical from 'src/components/historical/Historical';
import PrivateRoute from 'src/components/auth/require_authentication';

const history = createHashHistory({
  hashType: 'slash' // the default
});

export const getRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/publicInvestmentFunds" component={PublicInvestmentFunds} />
        <Route exact path="/publicInvestmentFunds/:portfolioId" component={PublicFundsDescription} />
        <PrivateRoute exact path="/signout" component={SignOut} />
        <PrivateRoute exact path="/resetPassword" component={ResetPassword} />
        <PrivateRoute exact path="/fundsList" component={FundsList} />
        <PrivateRoute exact path="/fundDescription/:portfolioId" component={FundDescription} />
        <PrivateRoute exact path="/fundDescription/:portfolioId/drillDownDetail/:detailId/asset/:assetId" component={DrillDownDetail} />
        <PrivateRoute exact path="/historical/:portfolioId" component={Historical} />
        <PrivateRoute exact path="/investmentsAndWidrawals/:portfolioId" component={InvestmentsAndWithdrawals} />
        <PrivateRoute exact path="/advisor/:portfolioId" component={Advisor} />
        <Redirect from="*" to="/fundsList" />
      </Switch>
    </Router>
  );
};
